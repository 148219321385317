import React from 'react'

import * as L from './Loader.styled'

interface LoaderProps {
  variant: L.LoaderVariantProps['variant']
  size?: number
}

export const Loader = ({ variant, size }: LoaderProps) => {
  return (
    <L.Container variant={variant} size={size}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </L.Container>
  )
}
